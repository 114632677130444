$(document).ready(function () {
    new WOW().init();
    $('.header-menu').scrollToFixed({
        zIndex: 999,
        marginTop: 0
    });

    if($(window).width() < 1199) {
		$('.btn-openmenu').prepend($('.language'));
	}

    $('.btn-openmenu').click(function () {
		$(this).find('.fa').toggleClass('fa-bars')
		$(this).find('.fa').toggleClass('fa-close')
        $('.menu-wrap').toggleClass('open');
        $('.overlay').fadeToggle(500);
    });
    $('.overlay').click(function () {
        $(this).fadeOut(500);
        $('.menu-wrap').removeClass('open');
    });

    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 100) {
            $('header').addClass('minimal');
        } else {
            $('header').removeClass('minimal');
        }
    });
    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 500) {
            $('.up').fadeIn(500);
        } else {
            $('.up').fadeOut(500);
        }
    });
    $('.up').click(function () {
        $('html').animate({
            scrollTop: 0
        }, 500);
    });

    $('.btn-showcate').click(function () {
        $('.category-group').toggleClass('open');
    });

    // BANNER
    $('.banner').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        dots: false,
        fade: true
    });

    $('.product-slide').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        infinite: false,
        fade: true,
        asNavFor: '.product-nav'
    });
    $('.product-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.product-slide',
        dots: false,
        focusOnSelect: true,
        infinite: true,
        swipe: true,
        swipeToSlide: true,
        vertical: true,
        verticalSwiping: true,
        responsive: [{
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 543,
            settings: {
                slidesToShow: 3,
                vertical: false,
                verticalSwiping: false
            }
        }
        ]
    });

    $('.other-slide').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        dots: false,
        swipe: true,
        swipeToSlide: true,
        infinite: true,
        arrows: true,
        responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
            }
        }
        ]
    });

    $('.list-galarry').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        dots: false,
        infinite: true,
        arrows: true,
        responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
            }
        }
        ]
    });

    $(function() {
        $(".lavalamp-list li").on("click mouseover", function(e) {
            var item = $(this);
            $(".lavalamp-bar").css("width", item.outerWidth());
            $(".lavalamp-bar").css("left", (item.offset().left - $(".lavalamp-list li:first").offset().left));

        if (e.type == "click") {
            $(".lavalamp-list li").removeClass("active");
            item.addClass("active");
        }
        });

        $(".lavalamp-list li").on("mouseout", function() {
            $(".lavalamp-list li.active").click();
        });

        $(".lavalamp-list li.active").click();
    })

    function time(){
        $( ".opa" ).css('left',0).animate({ "left": "150px" }, 1500);
    }
    setInterval(function(){time();},4000)
    time();
});
